import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { researchArticles } from '../../assets/research/researchArticles';
import { Article } from '../../types';
import Layout from '../Layout';
import SEO from '../SEO';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      width: '100%',
      maxWidth: '1024px',
      display: 'flex',
      justifyContent: 'center',
      boxShadow: '0 1px 5px 2px rgba(38,59,94,0.3)',
    },
  })
);

interface Props {
  pageContext: {
    articleID: string;
  };
}

const ResearchArticle = ({ pageContext: { articleID } }: Props) => {
  const classes = useStyles();
  let article: Article = researchArticles[0];

  // select the research article to display based on the id
  for (let i = 0; i < researchArticles.length; i++) {
    if (researchArticles[i].id === articleID) {
      article = researchArticles[i];
      break;
    }
  }

  return (
    <Layout>
      <SEO title={article.title} description={article.description} />
      <article
        className={classes.root}
        dangerouslySetInnerHTML={{ __html: article.html }}
      ></article>
    </Layout>
  );
};

export default ResearchArticle;
